import React, { useContext, useEffect, useState } from "react";
import styles from "./ReportsSpreadJs.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { I18nContext } from "../../i18n/I18nContext";
import {
  fetchTasksReport,
  fetchAssetsReport,
  fetchAttachmentsReport,
  fetchRecurrenceTasksReport,
  fetchTasksPrintReport,
} from "../../redux";
import Icon from "../Icon";

import GC from "@mescius/spread-sheets";
import { SpreadSheets, Worksheet } from "@mescius/spread-sheets-react";
import * as ExcelIO from "@mescius/spread-excelio";
import "@mescius/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import "@mescius/spread-sheets-shapes";
import Loader from "../Loader";

GC.Spread.Sheets.LicenseKey = ExcelIO.LicenseKey =
  "tapper.normecgroup.com,832164343969432#B16eudUbJ3GRD3CNyUGMDRnSwpmSiFUTWl7M6EmNn3SYyIlZJJTQ9EVSLZzZth4d7MTRltEazAnRvVzcwhUVt3kZDpXdv2WUBNzSER6TtJWc8B5cSh6bwITNuBnSotESulES5gEesl4aOpGZXRFcrBlaWR6MCVHbi3SM4MDOFBlUX3iYy2mZxIWWw8mZxx4RyJnQXBXTIhkV48GSLB7aJlFVlJnMT5mRmBHUV3kYpF4bUxEOKRUcRtCZGJ4L8UVdrFmUjBnY0Vjd4VmTsRUV8lGeklXW5UmajtkYQ9WbFhVOElHOHRTbOh4Y74kavsSYodnW8wEZaZTR0R5d6oVYiojITJCLiUzQ4UkREdjMiojIIJCL7gDNxUDMwYzM0IicfJye#4Xfd5nIIlkSCJiOiMkIsICOx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiQTNzEjMxACOxIDM5IDMyIiOiQncDJCLi46bj9Cc53mcnNWZtJ7bu9iclBHchRnI0IyctRkIsIiLW9iQgsWZlJ6csF6SgMWZtJ7bOJiOiEmTDJCLiIzM4kjN9MDNzQjNxIzM8IiOiQWSiwSfdJSZsJWYUR7b6lGUislOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBJlU8M5bu5EMQBXa6sWNxklbyY6RX3ybx86KoV5TG5EZBtyaUB7ZJFjRnRWO6EGblhlQaV7M7wGVElkUm9kb7dFR8RWd5EnRrMHMFRnWWRjcZVHdDFMOtN";

window.GC = GC;
const Reports = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.reports.loading);
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);
  const [spread, setSpread] = useState({});

  const hostStyle = {
    width: "100%",
    height: "100%",
  };

  const workbookInit = (data) => {
    setSpread(data);
  };

  const reportsList = [
    {
      type: "tasks_open",
      name: t("open tasks report"),
      method: fetchTasksReport,
    },
    {
      type: "tasks_closed",
      name: t("closed tasks report"),
      method: fetchTasksReport,
    },
    {
      type: "assets",
      name: t("assets report"),
      method: fetchAssetsReport,
    },
    {
      type: "assets_changes",
      name: t("assets changes report"),
      method: fetchAssetsReport,
    },
    {
      type: "attachments",
      name: t("attachments report"),
      method: fetchAttachmentsReport,
    },
    {
      type: "recurrence_tasks",
      name: t("recurrence tasks report"),
      method: fetchRecurrenceTasksReport,
    },
    {
      type: "tasks_print",
      name: t("tasks print report"),
      method: fetchTasksPrintReport,
    },
  ];

  const downloadReport = async (type) => {
    const reportItem = reportsList.find((x) => x.type === type);
    const fileName = reportItem.name;

    const report = await dispatch(
      reportItem.method(
        t,
        type,
        allChosen.map((x) => x.id)
      )
    );
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName.replace(" ", "_")}.xlsx`;
    document.body.appendChild(link);
    link.click();
  };

  const openReport = async (type) => {
    const reportItem = reportsList.find((x) => x.type === type);
    const report = await dispatch(
      reportItem.method(
        t,
        type,
        allChosen.map((x) => x.id)
      )
    );
    const blob = new Blob([report]);

    let excelIO = new ExcelIO.IO();
    excelIO.open(
      blob,
      (json) => {
        spread.fromJSON(json);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  return (
    <>
      <div className={styles.header_section}>
        <span className={styles.title}>{t("reports")}</span>
      </div>
      <div className={styles.reports_list}>
        {reportsList.map((report) => (
          <div className={styles.report_item} key={report.type}>
            <div className={styles.report_item_name}>{report.name}</div>
            <div className={styles.report_item_actions}>
              <div className={styles.action_button}>
                <Icon
                  name="eye-grey"
                  className={styles.action_button_icon}
                  onClick={() => openReport(report.type)}
                />
              </div>
              <div className={styles.action_button}>
                <Icon
                  name="download-grey"
                  className={styles.action_button_icon}
                  onClick={() => downloadReport(report.type)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {!!spread && (
        <div className={styles.report_preview}>
          <SpreadSheets
            // backColor={spreadBackColor}
            hostStyle={hostStyle}
            workbookInitialized={workbookInit}
          >
            <Worksheet></Worksheet>
          </SpreadSheets>
        </div>
      )}

      {!!loading && <Loader />}
    </>
  );
};
export default Reports;
